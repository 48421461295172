<template>
  <div class="bg-white h-full">
    <div class="p-4">
      <h2 class="fs-20 font-bold uppercase">{{ $t('Chương trình tích điểm') }}</h2>
      <HodoCreditLayout :type="HODO_CREDIT_TYPE.accumulate" />
    </div>
  </div>
</template>

<script>
import { HODO_CREDIT_TYPE } from '../../utils/constants'
import HodoCreditLayout from './CreditComponents/HodoCreditLayout.vue'

export default {
  name: 'AccumulatePoints',
  components: { HodoCreditLayout },
  data () {
    return {
      HODO_CREDIT_TYPE
    }
  }
}
</script>

<style lang="scss" scoped>
</style>